import React from 'react';
import { Flex, Textarea, Input, Button, Box, Text } from 'theme-ui';

export default function ContactForm({
  formName,
  variantName,
  city,
  isMultiLocationSite,
}) {
  return (
    <Box sx={customStyles.container}>
      <Text as='p' className='text'>
        Pricing varies according to availability and location. Please contact us
        for details!
      </Text>

      <Flex
        as='form'
        variant={`${variantName}.formContainer`}
        name={formName ? formName : 'contactformV2'}
        method='POST'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
      >
        <Input
          type='hidden'
          name='form-name'
          value={formName ? formName : 'contactformV2'}
        />
        <Input
          variant={`${variantName}.name`}
          name='name'
          type='text'
          id='name'
          mb={3}
          placeholder='Name'
          className='inputField name'
        />
        <Input
          variant={`${variantName}.email`}
          type='email'
          name='email'
          id='email'
          mb={3}
          placeholder='Email'
          className='inputField email'
        />
        <Input
          variant={`${variantName}.phone`}
          type='phone'
          name='phone'
          id='phone'
          mb={3}
          placeholder='Phone'
          className='inputField phone'
        />
        <Input
          variant={`${variantName}.name`}
          name='typeofevent'
          type='text'
          id='typeofevent'
          mb={3}
          placeholder='Type of Event'
          className='inputField name'
        />
        <Input
          variant={`${variantName}.name`}
          name='dateofevent'
          type='date'
          id='dateofevent'
          mb={3}
          placeholder='Date of Event'
          className='inputField name datefield'
          sx={{
            '::before': {
              content: "'Event Date - '",
            },
          }}
        />

        <Textarea
          name='message'
          id='message'
          variant={`${variantName}.message`}
          rows='6'
          mb={3}
          placeholder='Message'
          className='textField message'
        />
        {isMultiLocationSite ? (
          <Input
            sx={{ display: 'none !important' }}
            name='location'
            id='location'
            value={city}
            placeholder={city}
          />
        ) : (
          ''
        )}
        <Button className='button submitBtn'>Submit</Button>
      </Flex>
    </Box>
  );
}

const customStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 1rem',
    '.text': {
      marginBottom: '2rem',
    },
    form: {
      input: {
        borderColor: 'lightgrey',
        borderRadius: '0px',
      },
      textarea: {
        borderColor: 'lightgrey',
        borderRadius: '0px',
      },
      '.submitBtn': {
        padding: '1rem 1.5rem',
      },
    },
  },
};
