import React from 'react'
import Form from '../components/ContactForm/v1/Form'

export default function ContactFormV1(props) {
  console.log(props)
  return (
    <>
      <Form {...props} />
    </>
  )
}
