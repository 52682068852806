import React from 'react'
import { Flex, Text, Link } from '@theme-ui/components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons'

export default function ContactDetails({
  address,
  phone,
  email,
  alternativeGoogleMapLink,
}) {
  const { name, street, city, state, zip } = address || {}
  const googleString = address
    ? `https://www.google.com/maps/dir/?api=1&destination=${name}+${street}+${city}+${state}+${zip}`
    : ''

  return (
    <Flex
      variant='contactDetails.container'
      className='contactDetails-container'
    >
      {address ? (
        <Text variant='contactDetails.text' className='text address'>
          <Link
            href={
              alternativeGoogleMapLink ? alternativeGoogleMapLink : googleString
            }
            target='_blank'
            rel='noreferrer'
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} /> {`${street}`}
            <br></br>
            {`${city}, ${state}, ${zip}`}
          </Link>
        </Text>
      ) : (
        ''
      )}
      <Text variant='contactDetails.text' className='text phone'>
        <Link href={`tel:${phone}`} target='_blank' rel='noreferrer'>
          <FontAwesomeIcon icon={faPhone} /> {phone}
        </Link>
      </Text>
      {email ? (
        <Text variant='contactDetails.text' className='text email'>
          <FontAwesomeIcon icon={faEnvelope} /> {email}
        </Text>
      ) : (
        ''
      )}
    </Flex>
  )
}

// component takes an address and phone
